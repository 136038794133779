import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { format } from "../../intl.js";
import { saveAs } from "file-saver";
import moment from "moment";
import {
  useMediaQuery,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Container,
  TextField,
  CircularProgress,
  FormControl,
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ClearIcon from "@material-ui/icons/Clear";
import { getAllClients } from "../../actions/clientActions";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { useLocation } from "react-router-dom";

import ProgressButton from "react-progress-button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  deleteInvoice,
  getInvoicesByUser,
  getInvoices,
  getInvoicesByPage,
} from "../../actions/invoiceActions";
import { getUsers } from "../../actions/auth";
import NoData from "../svgIcons/NoData";
import Spinner from "../Spinner/Spinner";
import { useSnackbar } from "react-simple-snackbar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((_theme) => ({
  table: {
    minWidth: 500,
  },

  tablecell: {
    fontSize: "16px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  typography: {
    fontSize: "18px",
    fontWeight: 500,
  },
}));

const tableStyle = {
  width: 160,
  fontSize: 18,
  cursor: "pointer",
  borderBottom: "none",
  padding: "4px",
  textAlign: "center",
};
const headerStyle = {
  borderBottom: "none",
  textAlign: "center",
  fontSize: "22px",
};
const colorTheme = createTheme({
  palette: {
    primary: {
      main: "#ef7f1a",
    },
  },
});

const Invoices = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [downloadStatus, setDownloadStatus] = useState(null);
  const user = JSON.parse(localStorage.getItem("profile"));
  const { invoices, totalInvoices ,totalPages} = useSelector(
    (state) => state.invoices.invoices
  );

  // //(invoices);
  const [rows, setRows] = useState([]);
  const { users } = useSelector((state) => state.auth);
  const clients = useSelector((state) => state.clients.clients);
  // //(users);
  // //(rows);
  const [loading, setLoading] = useState(false);
  const isLoading = useSelector((state) => state.invoices.isLoading);
  const [searchQuery, setSearchQuery] = useState("");
  const [salesperson, setSalesperson] = useState(null);
  const [party, setParty] = useState(null);
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // const rows = []

  // useEffect(() => {
  //     dispatch(getInvoices());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getInvoicesByPage(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);
  useEffect(() => {
    if (invoices?.length > 0) {
      setRows(invoices);
    }
  }, [invoices]);
  useEffect(() => {
    dispatch(getUsers());
  }, [location]);
  useEffect(() => {
    dispatch(
      // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllClients()
    );

    // eslint-disable-next-line
  }, [dispatch]);
  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // useEffect(() => {
  //   if (user?.result?.userType === "user") {
  //     const rows = invoices.filter(
  //       (inv) => inv.creator[0] === user?.result?._id
  //     );
  //     setRows( rows.filter(filterByDateRange));
  //   } else {
  //     setRows(invoices.filter(filterByDateRange));
  //   }
  // }, );
  const findUserNameById = (userId) => {
    // //("parameter" + userId);
    const user = users.find((user) => user._id == userId);
    // //(user);
    return user ? user.name : "";
  };

  const filterByDateRange = (invoice) => {
    if (!startDate || !endDate) return true;
    const invoiceDate = moment(invoice.date);
    return invoiceDate.isBetween(startDate, endDate, "day", "[]");
  };
  const filteredRows = rows;
  // const filteredRows = rows.filter((row) => {
  //   const isSalespersonFilter = selectedFilter === "salesperson";
  //   const searchQueryLowerCase =
  //     searchQuery && typeof searchQuery === "string"
  //       ? searchQuery.toLowerCase()
  //       : "";

  //   if (isSalespersonFilter) {
  //     const creatorName = findUserNameById(row.creator).toLowerCase();
  //     return (
  //       filterByDateRange(row) && creatorName.includes(searchQueryLowerCase)
  //     );
  //   } else {
  //     const clientName = row.client.agentCode.toLowerCase()

  //     return (
  //       filterByDateRange(row) && clientName.includes(searchQueryLowerCase)
  //     );
  //   }
  // });

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value); // Update the selected filter when the user changes the option
  };
  const editInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };
  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this client?"
    );
    if (confirmDelete) {
      dispatch(deleteInvoice(id, openSnackbar));
    }
  };

  const openInvoice = (id) => {
    history.push(`/invoice/${id}`);
  };
  const createAndDownloadPdf = () => {
    //(rows);
    //(filteredRows);
    setLoading(true);
    if (filteredRows.length) {
      setDownloadStatus("loading");
      axios
        .post(`${process.env.REACT_APP_API}/create-report`, {
          filteredRows,
          users,
          // totalAmount,
        })
        .then(() =>
          axios.get(`${process.env.REACT_APP_API}/fetch-report`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // const downloadUrl = window.URL.createObjectURL(pdfBlob);
          // setDownloadStatus("success");
          // window.open(downloadUrl, "_blank");
          const currentDate = new Date();

          saveAs(pdfBlob, `BookingReport${currentDate}.pdf`);
          const downloadUrl = `https://qit.mindtree.co.th/files/Exported-Reports/report.pdf`;
          window.open(downloadUrl, "_blank");
          setLoading(false);
          setDownloadStatus("success");
        });
    } else {
      openSnackbar("No record to Export");
    }
  };
  const handleClientNameClick = (id) => {
    history.push(`/stats/${id}`);
  };
  if (!user) {
    history.push("/login");
  }

  const handleStartDateChange = (date) => {
    // const formattedDate = date.toISOString().split("T")[0];
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    // const formattedDate = date.toISOString().split("T")[0];
    setEndDate(date);
  };
  // const totalAmount = filteredRows.reduce((total, row) => {
  //   // Ensure that each row has an 'amount' property
  //   if (typeof row.total === "number") {
  //     return total + row.total;
  //   } else {
  //     return total;
  //   }
  // }, 0);
  const salespersonsProps = {
    options: users,
    getOptionLabel: (option) => option.name,
  };
  // }
  // function checkStatus(status) {
  //   return status === "Partial"
  //     ? {
  //         border: "solid 0px #1976d2",
  //         backgroundColor: "#baddff",
  //         padding: "8px 18px",
  //         borderRadius: "20px",
  //       }
  //     : status === "Paid"
  //     ? {
  //         border: "solid 0px green",
  //         backgroundColor: "#a5ffcd",
  //         padding: "8px 18px",
  //         borderRadius: "20px",
  //       }
  //     : status === "Unpaid"
  //     ? {
  //         border: "solid 0px red",
  //         backgroundColor: "#ffaa91",
  //         padding: "8px 18px",
  //         borderRadius: "20px",
  //       }
  //     : "red";
  // }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (rows.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
          margin: "80px",
        }}
      >
        <NoData />
        <p style={{ padding: "40px", color: "gray", textAlign: "center" }}>
          No Order yet. Click the plus icon to create Order
        </p>
      </div>
    );
  }
  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => option.agentCode,
  };
  return (
    <div
      style={{
        minHeight: isMobile ? "740px" : "470px",
      }}
    >
      <ThemeProvider theme={colorTheme}>
        <Container
          style={{
            width: isMobile ? "100%" : "85%",
            paddingTop: "2px",
            paddingBottom: "2px",
            border: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="From"
                  name="FromDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                  required
                  format="dd/MM/yyyy"
                  style={{ maxWidth: isMobile ? "50%" : "100%" }}
                />

                <KeyboardDatePicker
                  label="To"
                  name="EndDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                  required
                  format="dd/MM/yyyy"
                  style={{ maxWidth: "50%" }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              {user?.result?.userType === "admin" && (
                <FormControl style={{ width: "100px" }}>
                  <InputLabel>Filter:</InputLabel>
                  <Select
                    value={selectedFilter}
                    onChange={(event) => handleFilterChange(event)} // Provide the event object
                  >
                    <MenuItem value="salesperson">Filter by User</MenuItem>
                    <MenuItem value="customer">Filter by Hotel/Agent</MenuItem>
                  </Select>
                  {selectedFilter && (
                    <IconButton
                      size="small"
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "transparent",

                        color: "#ef7f1a",
                        borderRadius: "4px",
                      }}
                      onClick={() => {
                        setSelectedFilter("");
                        setSearchQuery("");
                        setSalesperson("");
                        setParty("");
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </FormControl>
              )}
              {selectedFilter && (
                <div>
                  {/* Display filtering results or perform filtering logic based on selectedFilter */}
                  {selectedFilter === "salesperson" ? (
                    <ThemeProvider theme={colorTheme}>
                      <Container>
                        {salesperson && (
                          <div
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              gap: "5px",
                              height: "50px",
                            }}
                          >
                            <Typography variant="subtitle1" gutterBottom>
                              {salesperson.name}
                            </Typography>

                            <Button
                              color="primary"
                              size="small"
                              style={{
                                textTransform: "none",
                                backgroundColor: "#ef7f1a",
                                color: "white",
                              }}
                              onClick={() => {
                                setSalesperson(null);
                                setSearchQuery("");
                              }}
                            >
                              Change
                            </Button>
                          </div>
                        )}
                        <div
                          style={
                            salesperson
                              ? { display: "none" }
                              : { display: "block", width: "200px" }
                          }
                        >
                          <Autocomplete
                            {...salespersonsProps}
                            // PaperComponent={CustomPaper}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // required={!invoice && true}
                                label="Select Sales Person"
                                margin="normal"
                                variant="outlined"

                                // error={touched && !!errors.client}
                                // helperText={touched && errors.client}
                              />
                            )}
                            value={users?.name}
                            onChange={(event, value) => {
                              setSalesperson(value);
                              setSearchQuery(value ? value.name : "");

                              console.log("value: ", value);
                              // handleClientChange();
                            }}
                            // onChange={(event, value) => setClient(value) }
                          />
                        </div>
                      </Container>
                    </ThemeProvider>
                  ) : selectedFilter === "customer" ? (
                    <ThemeProvider theme={colorTheme}>
                      <Container>
                        {party && (
                          <div
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              gap: "10px",
                              height: "50px",
                            }}
                          >
                            <Typography variant="subtitle1" gutterBottom>
                              {party.agentCode}
                            </Typography>

                            <Button
                              color="primary"
                              size="small"
                              style={{
                                textTransform: "none",
                                backgroundColor: "#ef7f1a",
                                color: "white",
                              }}
                              onClick={() => {
                                setParty(null);
                                setSearchQuery("");
                              }}
                            >
                              Change
                            </Button>
                          </div>
                        )}
                        <div
                          style={
                            party
                              ? { display: "none" }
                              : { display: "block", width: "200px" }
                          }
                        >
                          <Autocomplete
                            {...clientsProps}
                            // PaperComponent={CustomPaper}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // required={!invoice && true}
                                label="Select Agent/Hotel"
                                margin="normal"
                                variant="outlined"
                                // error={touched && !!errors.client}
                                // helperText={touched && errors.client}
                              />
                            )}
                            value={clients?.name}
                            onChange={(event, value) => {
                              setParty(value);
                              setSearchQuery(value ? value.agentCode : "");

                              // handleClientChange();
                            }}
                            // onChange={(event, value) => setClient(value) }
                          />
                        </div>
                      </Container>
                    </ThemeProvider>
                  ) : null}
                </div>
              )}
              {user?.result?.userType !== "admin" && (
                <ThemeProvider theme={colorTheme}>
                  <Container>
                    {party && (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "20px",
                          gap: "10px",
                          height: "50px",
                        }}
                      >
                        <Typography variant="subtitle1" gutterBottom>
                          {party.agentCode}
                        </Typography>

                        <Button
                          color="primary"
                          size="small"
                          style={{
                            textTransform: "none",
                            backgroundColor: "#ef7f1a",
                            color: "white",
                          }}
                          onClick={() => {
                            setParty(null);
                            setSearchQuery("");
                          }}
                        >
                          Change
                        </Button>
                      </div>
                    )}
                    <div
                      style={
                        party
                          ? { display: "none" }
                          : { display: "block", width: "200px" }
                      }
                    >
                      <Autocomplete
                        {...clientsProps}
                        // PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // required={!invoice && true}
                            label="Select Hotel/Agent"
                            margin="normal"
                            variant="outlined"
                            // error={touched && !!errors.client}
                            // helperText={touched && errors.client}
                          />
                        )}
                        value={clients?.name}
                        onChange={(event, value) => {
                          setParty(value);
                          setSearchQuery(value ? value.agentCode : "");

                          // handleClientChange();
                        }}
                        // onChange={(event, value) => setClient(value) }
                      />
                    </div>
                  </Container>
                </ThemeProvider>
              )}

              {loading ? (
                <CircularProgress
                  style={{ marginTop: "20px", marginLeft: "10px" }}
                />
              ) : (
                user?.result?.userType === "admin" && (
                  <Button
                    variant="outlined"
                    endIcon={<GetAppIcon />}
                    onClick={createAndDownloadPdf}
                    style={{
                      margin: "16px 0 8px 0",
                    }}
                  >
                    PDF
                  </Button>
                )
              )}
            </div>
            {/* {((startDate && endDate) || salesperson || party) && (
              <div
                className={classes.textContainer}
                style={{ margin: isMobile ? "0" : "10px 0 0 2rem" }}
              >
                <Typography variant="h6" className={classes.typography}>
                  Total Bookings: {filteredRows.length}
                </Typography>

                <Typography variant="h6" className={classes.typography}>
                  Total Booking Amount: {format(totalAmount)}
                </Typography>
              </div>
            )} */}
          </div>

          <TableContainer component={Paper} elevation={0}>
            <Table
              className={classes.table}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={headerStyle}>ID</TableCell>

                  <TableCell style={headerStyle}>Booking Date</TableCell>

                  {/* <TableCell style={headerStyle}>Hotel/Agent</TableCell> */}
                  <TableCell style={headerStyle}>Agent Code</TableCell>
                  <TableCell style={headerStyle}>Extra Selling Total</TableCell>
                  <TableCell style={headerStyle}>Payable Total</TableCell>
                  <TableCell style={headerStyle}>Commission</TableCell>
                  <TableCell style={headerStyle}>Expense</TableCell>
                  <TableCell style={headerStyle}>Amount</TableCell>
                  {/* <TableCell style={headerStyle}>Due Date</TableCell> */}

                  {/* <TableCell style={headerStyle}>Status</TableCell> */}
                  {/* {user?.result?.userType === "admin" && (
                    <TableCell style={headerStyle}>User</TableCell>
                  )} */}
                  {/* <TableCell style={headerStyle}>View</TableCell> */}

                  <TableCell style={headerStyle}>Edit</TableCell>

                  {user?.result?.userType === "admin" && (
                    <TableCell style={headerStyle}>Delete</TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {invoices.map((row) => (
                  <TableRow key={row._id} style={{ cursor: "pointer" }}>
                    <TableCell
                      style={tableStyle}
                      onClick={() => openInvoice(row._id)}
                    >
                      {" "}
                      {row.invoiceNumber}{" "}
                    </TableCell>
                    <TableCell
                      style={tableStyle}
                      onClick={() => openInvoice(row._id)}
                    >
                      {" "}
                      {moment(row.date).format("Do MMM YYYY")}{" "}
                    </TableCell>

                    {/* <TableCell
                      style={tableStyle}
                      // onClick={() => {
                      //   user?.result?.userType === "admin" &&
                      //     handleClientNameClick(row._id);
                      // }}
                    >
                      {" "}
                      {row.client.name?row.client.name:"Cash"}{" "}
                    </TableCell>
                     */}
                    <TableCell
                      style={tableStyle}
                      // onClick={() => {
                      //   user?.result?.userType === "admin" &&
                      //     handleClientNameClick(row._id);
                      // }}
                    >
                      {" "}
                      {row.client ? row.client.agentCode : "Cash"}{" "}
                    </TableCell>
                    <TableCell
                      style={tableStyle}
                      // onClick={() => {
                      //   user?.result?.userType === "admin" &&
                      //     handleClientNameClick(row._id);
                      // }}
                    >
                      {" "}
                      {row.currency}{" "}
                      {row.subTotalSelling
                        ? toCommas(row.subTotalSelling)
                        : "0"}{" "}
                    </TableCell>
                    <TableCell
                      style={tableStyle}
                      // onClick={() => {
                      //   user?.result?.userType === "admin" &&
                      //     handleClientNameClick(row._id);
                      // }}
                    >
                      {" "}
                      {row.currency}{" "}
                      {row.subTotalExpense
                        ? toCommas(row.subTotalExpense)
                        : "0"}{" "}
                    </TableCell>
                    <TableCell
                      style={tableStyle}
                      onClick={() => openInvoice(row._id)}
                    >
                      {row.currency}{" "}
                      {row.commission
                        ? toCommas(row.commission)
                        : row.commission}{" "}
                    </TableCell>
                    <TableCell
                      style={tableStyle}
                      onClick={() => openInvoice(row._id)}
                    >
                      {row.currency}{" "}
                      {row.expense ? toCommas(row.expense) : row.expense}{" "}
                    </TableCell>
                    <TableCell
                      style={tableStyle}
                      onClick={() => openInvoice(row._id)}
                    >
                      {row.currency}{" "}
                      {row.total ? toCommas(row.total) : row.total}{" "}
                    </TableCell>
                    {/* <TableCell style={tableStyle} onClick={() => openInvoice(row._id)}>
                    {" "}
                    {moment(row.dueDate).fromNow()}{" "}
                  </TableCell> */}
                    {/* <TableCell
                    style={tableStyle}
                  >
                    {" "}
                    <button style={checkStatus(row.status)}>
                      {row.status}
                    </button>
                  </TableCell> */}
                    {/* {user?.result?.userType === "admin" && (
                      <TableCell
                        style={tableStyle}
                        onClick={() => openInvoice(row._id)}
                      >
                        {" "}
                        {findUserNameById(row.creator)}{" "}
                       
                      </TableCell>
                    )} */}
                    {/* <TableCell style={tableStyle}>
                    {" "}
                    <IconButton onClick={() => openInvoice(row._id)}>
                      <RemoveRedEyeOutlinedIcon
                        style={{ width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </TableCell> */}

                    <TableCell style={{ ...tableStyle, width: "10px" }}>
                      <IconButton onClick={() => editInvoice(row._id)}>
                        <BorderColorIcon
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </TableCell>

                    {user?.result?.userType === "admin" && (
                      <TableCell style={{ ...tableStyle, width: "10px" }}>
                        <IconButton onClick={() => handleDelete(row._id)}>
                          <DeleteOutlineRoundedIcon
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: totalInvoices },
                    ]}
                    colSpan={6}
                    count={totalPages}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default Invoices;
